<app-top-nav></app-top-nav>

<div class="d-flex flex-column py-2 content-col">

  <button mat-raised-button (click)="changePassword()" class="my-2 px-3 py-2 text-left">
    Change password
  </button>

  <button mat-raised-button [routerLink]="['/settings', 'delete-account']" class="my-2 px-3 py-2 text-left">
    Delete Account
  </button>

  <div>
    <hr>
  </div>

  <button mat-raised-button (click)="logout()" class="my-2 px-3 py-2 text-left">
    <span class="">Logout</span>
  </button>

</div>

