<div class="log-in-container">

  <div class="header">
    <app-account-header></app-account-header>
  </div>

  <div class="content">
    <div class="main-button-wrapper">
      <button mat-raised-button color="primary" [routerLink]="['/account/oauth2/log-in']">
        SIGN IN WITH I7
      </button>
    </div>
    <div class="other-oauth2-providers">
      <button mat-raised-button [routerLink]="['/account/oauth2/log-in']">
        GOOGLE
      </button>
      <button mat-raised-button [routerLink]="['/account/oauth2/log-in']">
        FACEBOOK
      </button>
    </div>
  </div>


  <div class="footer">
    <div class="text-center">
      <a [routerLink]="['/account/restore-password']">Forgot password? Click
        here</a>
    </div>
    <div class="text-center">
      <a [routerLink]="['/account/registration']">Don't have account? Create it
        here</a>
    </div>

  </div>

</div>
