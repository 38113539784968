import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { throwError, of, Subject, pipe } from 'rxjs';
import { catchError, map, tap, shareReplay } from 'rxjs/operators';
import { User } from '../shared/models/user';
import { FriendRequest } from '../shared/models/friend-request';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var API_URL = environment.apiUrl;
var UserService = /** @class */ (function () {
    function UserService(http) {
        var _this = this;
        this.http = http;
        this.$friend = new Subject();
        this.fetchCurrentUser = function () {
            return _this.$currentUser = _this.http.get(API_URL + "/users/me")
                .pipe(map(function (user) { return new User(user); }), tap(function (user) { return _this.currentUser = user; }), shareReplay());
        };
        this.getFriendRequests = function () {
            _this.http.get(API_URL + "/friend-requests/")
                .pipe(map(function (data) { return data.filter(function (x) { return x.sender.id !== _this.currentUser.id; }); }))
                .subscribe(function (requests) {
                _this.friendRequests = requests;
                _this.$friendRequest.next(_this.friendRequests);
            });
            return _this.$friendRequest.asObservable();
        };
        this.getPendingRequests = function () {
            _this.http.get(API_URL + "/friend-requests/")
                .pipe(map(function (data) { return data.filter(function (x) { return x.sender.id === _this.currentUser.id; }); }))
                .subscribe(function (requests) {
                _this.pendingRequests = requests;
                _this.$pendingRequest.next(_this.pendingRequests);
            });
            return _this.$pendingRequest.asObservable();
        };
        this.getFriends = function () {
            _this.http.get(API_URL + "/friends/").subscribe(function (friends) {
                _this.friends = friends;
                _this.$friend.next(_this.friends);
            });
            return _this.$friend.asObservable();
        };
        this.clearData = function () {
            _this.initialize();
        };
        this.initialize();
    }
    UserService.prototype.initialize = function () {
        this.$friendRequest = new Subject();
        this.$pendingRequest = new Subject();
        this.$friend = new Subject();
        this.friends = [];
        this.friendRequests = [];
        this.pendingRequests = [];
        this.currentUser = null;
        this.$currentUser = null;
    };
    UserService.prototype.getCurrentUser = function () {
        if (this.currentUser) {
            return of(this.currentUser);
        }
        if (this.$currentUser) {
            return this.$currentUser;
        }
        return this.fetchCurrentUser();
    };
    UserService.prototype.getUser = function (id) {
        return this.http.get(API_URL + "/users/" + id).pipe(map(function (user) { return new User(user); }));
    };
    UserService.prototype.sendFriendRequest = function (username) {
        return this.http.post(API_URL + "/friend-requests/", { receiver: username });
    };
    UserService.prototype.getPendingFriendRequest = function (userId) {
        return this.http.get(API_URL + "/friend-requests/?sender=" + userId).pipe(map(function (request) { return new FriendRequest(request[0]); }));
    };
    UserService.prototype.deleteFriendRequest = function (id) {
        this.http.delete(API_URL + "/friend-requests/" + id).subscribe(pipe(this.getFriendRequests, this.getPendingRequests));
        return this.$friendRequest.asObservable();
    };
    UserService.prototype.acceptFriendRequest = function (id) {
        this.http.post(API_URL + "/friend-requests/" + id + "/accept", { id: id })
            .subscribe(pipe(this.getFriendRequests, this.getFriends));
        return this.$friendRequest.asObservable();
    };
    UserService.prototype.deleteFriend = function (username) {
        this.http.delete(API_URL + "/friends/" + username).subscribe(this.getFriends);
        return this.$friend.asObservable();
    };
    UserService.prototype.setPicure = function (image) {
        var data = new FormData();
        data.append('profile_picture_normal', image);
        return this.http.put(API_URL + "/users/me/profile-picture", data).pipe(catchError(this.handleError('set profile picture')));
    };
    UserService.prototype.changePassword = function (changePassword) {
        return this.http.post(API_URL + "/users/" + this.currentUser.id + "/change-password", changePassword);
    };
    UserService.prototype.handleError = function (operation) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            console.log(error);
            console.log(operation + " failed: " + error.message);
            return throwError(error.error);
        };
    };
    UserService.prototype.deleteAccount = function () {
        return this.http.delete(API_URL + "/users/me").pipe();
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
