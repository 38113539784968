var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Message } from '../../../../../shared/models/message';
import { MessageService } from '../../../../../services/message.service';
import { Router, ActivatedRoute } from '@angular/router';
var MessageComponent = /** @class */ (function () {
    function MessageComponent(messageService, router, route) {
        var _this = this;
        this.messageService = messageService;
        this.router = router;
        this.route = route;
        this.hideSeeAllButton = false;
        this.messageDetails = false;
        this.showMenu = false;
        this.removable = false;
        this.editable = false;
        this.handleParams = function (params) {
            if (params['messageId'] === _this.message.id) {
                _this.hideSeeAllButton = true;
            }
        };
        this.handleVoteClick = function () {
            _this.message.my_like
                ? _this.messageService.handleMessageUnlike(_this.message)
                : _this.messageService.handleMessageLike(_this.message);
        };
    }
    MessageComponent.prototype.ngOnInit = function () {
        this.route.params.subscribe(this.handleParams);
    };
    MessageComponent.prototype.deleteMessage = function () {
        this.messageService.deleteMessage(this.message);
    };
    MessageComponent.prototype.editMessage = function () {
        this.editMode = true;
        this.editedMessage = __assign({}, this.message);
    };
    MessageComponent.prototype.cancelEdit = function () {
        this.editMode = false;
        this.editedMessage = null;
    };
    MessageComponent.prototype.saveMessage = function () {
        var _this = this;
        if (!this.editedMessage.body) {
            return;
        }
        this.messageService.updateMessage(this.editedMessage).subscribe(function () {
            _this.editMode = false;
            _this.message = _this.editedMessage;
        });
    };
    MessageComponent.prototype.gotoUserProfile = function (id) {
        this.router.navigate(["/users/" + id]);
    };
    MessageComponent.prototype.goToMessageDetails = function () {
        this.router.navigate(["/events/" + this.message.i7event + "/messages/" + this.message.id]);
    };
    MessageComponent.prototype.handleReplyClick = function () {
        this.message.replyClicked = !this.message.replyClicked;
    };
    return MessageComponent;
}());
export { MessageComponent };
