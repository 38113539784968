/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-account.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../utils/components/top-nav/top-nav.component.ngfactory";
import * as i3 from "../../../utils/components/top-nav/top-nav.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/router";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./delete-account.component";
var styles_DeleteAccountComponent = [i0.styles];
var RenderType_DeleteAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteAccountComponent, data: {} });
export { RenderType_DeleteAccountComponent as RenderType_DeleteAccountComponent };
export function View_DeleteAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-nav", [], null, null, null, i2.View_TopNavComponent_0, i2.RenderType_TopNavComponent)), i1.ɵdid(1, 114688, null, 0, i3.TopNavComponent, [i4.Location], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "content-col p-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure?"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["After the account is deleted, you are not longer able to access it."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All of your personal data will be deleted. Content created by you won't be deleted. Photos, events and messages that you've created will remain on the platform. "])), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "d-flex flex-row-reverse mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["color", "warn"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(12, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), i1.ɵdid(14, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Delete the account"])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["class", "mx-2"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(17, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(18, 1), i1.ɵdid(19, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = _ck(_v, 13, 0, "confirm"); _ck(_v, 12, 0, currVal_2); var currVal_3 = "warn"; _ck(_v, 14, 0, currVal_3); var currVal_6 = _ck(_v, 18, 0, "/settings"); _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 14).disabled || null); var currVal_1 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 19).disabled || null); var currVal_5 = (i1.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_4, currVal_5); }); }
export function View_DeleteAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-account", [], null, null, null, View_DeleteAccountComponent_0, RenderType_DeleteAccountComponent)), i1.ɵdid(1, 114688, null, 0, i11.DeleteAccountComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteAccountComponentNgFactory = i1.ɵccf("app-delete-account", i11.DeleteAccountComponent, View_DeleteAccountComponent_Host_0, {}, {}, []);
export { DeleteAccountComponentNgFactory as DeleteAccountComponentNgFactory };
