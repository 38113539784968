/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-short.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./event-short.component";
var styles_EventShortComponent = [i0.styles];
var RenderType_EventShortComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventShortComponent, data: {} });
export { RenderType_EventShortComponent as RenderType_EventShortComponent };
function View_EventShortComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "text-muted date"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Starts ", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.event.start)); _ck(_v, 1, 0, currVal_0); }); }
function View_EventShortComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "text-muted date"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Ended ", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.event.end)); _ck(_v, 1, 0, currVal_0); }); }
function View_EventShortComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event.getShortDescription(); _ck(_v, 2, 0, currVal_0); }); }
function View_EventShortComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "mx-2 btn btn-sm btn-outline-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 3), (_l()(), i1.ɵted(-1, null, ["Participants "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, "/events", _co.event.id, "users"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EventShortComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 28, "div", [["class", "shadow w-100 my-3 d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "cover"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 26, "div", [["class", "p-3 d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 2), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventShortComponent_1)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventShortComponent_2)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventShortComponent_3)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 10, "div", [["class", "d-flex flex-row-reverse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "button", [["class", "mx-2 btn btn-sm btn-outline-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(22, 2), (_l()(), i1.ɵted(-1, null, ["Details"])), (_l()(), i1.ɵeld(24, 0, null, null, 3, "button", [["class", "mx-2 btn btn-sm btn-outline-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(26, 3), (_l()(), i1.ɵted(-1, null, ["Options "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventShortComponent_4)), i1.ɵdid(29, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 7, 0, "/events", _co.event.id); _ck(_v, 6, 0, currVal_3); var currVal_5 = _co.showStart; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.showEnd; _ck(_v, 14, 0, currVal_6); var currVal_7 = _co.event.description; _ck(_v, 16, 0, currVal_7); var currVal_8 = _ck(_v, 22, 0, "/events", _co.event.id); _ck(_v, 21, 0, currVal_8); var currVal_9 = _ck(_v, 26, 0, "/events", _co.event.id, "settings"); _ck(_v, 25, 0, currVal_9); var currVal_10 = !_co.event.public; _ck(_v, 29, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event.image_normal; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).target; var currVal_2 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = _co.event.title; _ck(_v, 9, 0, currVal_4); }); }
export function View_EventShortComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-short", [], null, null, null, View_EventShortComponent_0, RenderType_EventShortComponent)), i1.ɵdid(1, 114688, null, 0, i4.EventShortComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventShortComponentNgFactory = i1.ɵccf("app-event-short", i4.EventShortComponent, View_EventShortComponent_Host_0, { event: "event", showStart: "showStart", showEnd: "showEnd" }, {}, []);
export { EventShortComponentNgFactory as EventShortComponentNgFactory };
