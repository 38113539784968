<div class="header">
  <app-account-header></app-account-header>
</div>
<div class="form" *ngIf="user">
  <h4 class="text-center">Set a new password</h4>

  <app-avatar class="mx-auto" [imgSrc]="user.profile_picture_small"></app-avatar>
  
  <span class="text-center username my-2">{{user.username}}</span>

  <form *ngIf="!loading else spinner" (ngSubmit)="onSubmit()" [formGroup]="form">
    <mat-form-field class="input" [ngClass]="error?.password.length > 50 ? 'my-3' : 'my-1'">

      <input  type="password"
              matInput 
              placeholder="Enter a new password" 
              formControlName="password">

      <mat-error *ngIf="form.controls.password.errors?.required">This field is required</mat-error>
      <mat-error *ngIf="form.controls.password.errors?.minlength">Minimum length is 3</mat-error>
      <mat-error *ngIf="form.controls.password.errors?.backend">{{error?.password}}</mat-error>
    </mat-form-field>

    <mat-form-field class="input">
      <input type="password" matInput placeholder="Confirm the password" formControlName="confirmPassword">
      <mat-error *ngIf="form.controls.confirmPassword.errors?.required">This field is required</mat-error>
      <mat-error *ngIf="form.controls.confirmPassword.errors?.minlength">Minimum length is 3</mat-error>
      <mat-error *ngIf="form.controls.confirmPassword.errors?.MatchPassword">Passwords are not the same</mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="logout_other_sessions" class="my-3" [checked]="true">Logout from other sessions</mat-checkbox>

    <button class="my-2" style="color: #548EFF;" mat-raised-button>Submit</button>

  </form>
  <ng-template #spinner><mat-spinner class="mx-auto"></mat-spinner></ng-template>
</div>


<div class="footer">
  <div class="text-center">
    <a [routerLink]="['/account/log-in']">Go back to log-in page</a>
  </div>
</div>