/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authored-events.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../utils/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../utils/components/spinner/spinner.component";
import * as i4 from "../../../utils/components/event-short/event-short.component.ngfactory";
import * as i5 from "../../../utils/components/event-short/event-short.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/router";
import * as i10 from "./authored-events.component";
import * as i11 from "../../../../services/i7event.service";
var styles_AuthoredEventsComponent = [i0.styles];
var RenderType_AuthoredEventsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthoredEventsComponent, data: {} });
export { RenderType_AuthoredEventsComponent as RenderType_AuthoredEventsComponent };
function View_AuthoredEventsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AuthoredEventsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-muted text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You don't have any future events."]))], null, null); }
function View_AuthoredEventsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-short", [], null, null, null, i4.View_EventShortComponent_0, i4.RenderType_EventShortComponent)), i1.ɵdid(1, 114688, null, 0, i5.EventShortComponent, [], { event: [0, "event"], showStart: [1, "showStart"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AuthoredEventsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Future Events"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthoredEventsComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthoredEventsComponent_4)), i1.ɵdid(8, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.eventsFuture.length; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.eventsFuture; _ck(_v, 8, 0, currVal_1); }, null); }
function View_AuthoredEventsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-muted text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You don't have any past events."]))], null, null); }
function View_AuthoredEventsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-short", [], null, null, null, i4.View_EventShortComponent_0, i4.RenderType_EventShortComponent)), i1.ɵdid(1, 114688, null, 0, i5.EventShortComponent, [], { event: [0, "event"], showEnd: [1, "showEnd"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AuthoredEventsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Past Events"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthoredEventsComponent_6)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthoredEventsComponent_7)), i1.ɵdid(8, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.eventsPast.length; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.eventsPast; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_AuthoredEventsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "d-flex my-3 justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "pointer p-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "d-flex align-items-center back-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "text-muted mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_back_ios"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "btn btn-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i9.RouterLink, [i9.Router, i9.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 2), (_l()(), i1.ɵted(-1, null, ["Create new event"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthoredEventsComponent_1)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthoredEventsComponent_2)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthoredEventsComponent_5)), i1.ɵdid(18, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_2 = _ck(_v, 11, 0, "/events", "create"); _ck(_v, 10, 0, currVal_2); var currVal_3 = (!_co.loadedFuture && !_co.loadedPast); _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.loadedFuture; _ck(_v, 16, 0, currVal_4); var currVal_5 = _co.loadedPast; _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).inline; var currVal_1 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_AuthoredEventsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-authored-events", [], null, null, null, View_AuthoredEventsComponent_0, RenderType_AuthoredEventsComponent)), i1.ɵdid(1, 114688, null, 0, i10.AuthoredEventsComponent, [i11.I7EventService, i6.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthoredEventsComponentNgFactory = i1.ɵccf("app-authored-events", i10.AuthoredEventsComponent, View_AuthoredEventsComponent_Host_0, {}, {}, []);
export { AuthoredEventsComponentNgFactory as AuthoredEventsComponentNgFactory };
