/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-album.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../utils/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../utils/components/spinner/spinner.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./profile-album.component";
import * as i7 from "../../../../services/i7event.service";
var styles_ProfileAlbumComponent = [i0.styles];
var RenderType_ProfileAlbumComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileAlbumComponent, data: {} });
export { RenderType_ProfileAlbumComponent as RenderType_ProfileAlbumComponent };
function View_ProfileAlbumComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ProfileAlbumComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "photo-wrap pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 3), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "ext-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "ext-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "/events", _v.context.$implicit.id, "photos"); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.image_normal; _ck(_v, 5, 0, currVal_1); }); }
function View_ProfileAlbumComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "photo-library"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAlbumComponent_3)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProfileAlbumComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "text-muted text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Events that you took part in will be displayed here."]))], null, null); }
export function View_ProfileAlbumComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAlbumComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAlbumComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAlbumComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loaded; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loaded; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.loaded && !_co.events.length); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ProfileAlbumComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-album", [], null, null, null, View_ProfileAlbumComponent_0, RenderType_ProfileAlbumComponent)), i1.ɵdid(1, 114688, null, 0, i6.ProfileAlbumComponent, [i7.I7EventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileAlbumComponentNgFactory = i1.ɵccf("app-profile-album", i6.ProfileAlbumComponent, View_ProfileAlbumComponent_Host_0, {}, {}, []);
export { ProfileAlbumComponentNgFactory as ProfileAlbumComponentNgFactory };
