/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./restore-password-success.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../helpers/header/header.component.ngfactory";
import * as i3 from "../helpers/header/header.component";
import * as i4 from "../../../utils/components/avatar/avatar.component.ngfactory";
import * as i5 from "../../../utils/components/avatar/avatar.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "./restore-password-success.component";
import * as i9 from "../../../../services/password-restoration.service";
var styles_RestorePasswordSuccessComponent = [i0.styles];
var RenderType_RestorePasswordSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RestorePasswordSuccessComponent, data: {} });
export { RenderType_RestorePasswordSuccessComponent as RenderType_RestorePasswordSuccessComponent };
export function View_RestorePasswordSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-account-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-avatar", [["class", "mx-auto"]], null, null, null, i4.View_AvatarComponent_0, i4.RenderType_AvatarComponent)), i1.ɵdid(4, 114688, null, 0, i5.AvatarComponent, [], { imgSrc: [0, "imgSrc"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "text-center mx-auto my-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "text-center my-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your password has been successfully set.\n"])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵted(-1, null, ["Go back to log-in page"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.profile_picture_small; _ck(_v, 4, 0, currVal_0); var currVal_4 = _ck(_v, 13, 0, "/account/log-in"); _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.user.username; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 12).target; var currVal_3 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_2, currVal_3); }); }
export function View_RestorePasswordSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-restore-password-success", [], null, null, null, View_RestorePasswordSuccessComponent_0, RenderType_RestorePasswordSuccessComponent)), i1.ɵdid(1, 114688, null, 0, i8.RestorePasswordSuccessComponent, [i9.PasswordRestorationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RestorePasswordSuccessComponentNgFactory = i1.ɵccf("app-restore-password-success", i8.RestorePasswordSuccessComponent, View_RestorePasswordSuccessComponent_Host_0, {}, {}, []);
export { RestorePasswordSuccessComponentNgFactory as RestorePasswordSuccessComponentNgFactory };
