/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./friends-bar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./friends-bar.component";
import * as i5 from "../../../../services/user.service";
var styles_FriendsBarComponent = [i0.styles];
var RenderType_FriendsBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FriendsBarComponent, data: {} });
export { RenderType_FriendsBarComponent as RenderType_FriendsBarComponent };
function View_FriendsBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "friend-tile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "friend-avatar shadow-sm pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), i1.ɵdid(4, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 3, "a", [["class", "text-center username pt-1"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 2), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + _v.context.$implicit.profile_picture_small) + ")")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, "/users/", _v.context.$implicit.id); _ck(_v, 4, 0, currVal_1); var currVal_4 = _ck(_v, 8, 0, "/users/", _v.context.$implicit.id); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 7).target; var currVal_3 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.username; _ck(_v, 9, 0, currVal_5); }); }
export function View_FriendsBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "friends-preview-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "friends-preview"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FriendsBarComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "opacity-cover"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.friends; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_FriendsBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-friends-bar", [], null, null, null, View_FriendsBarComponent_0, RenderType_FriendsBarComponent)), i1.ɵdid(1, 114688, null, 0, i4.FriendsBarComponent, [i5.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FriendsBarComponentNgFactory = i1.ɵccf("app-friends-bar", i4.FriendsBarComponent, View_FriendsBarComponent_Host_0, {}, {}, []);
export { FriendsBarComponentNgFactory as FriendsBarComponentNgFactory };
