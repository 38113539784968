<div class="restore-password-container">

  <div class="header">
    <app-account-header></app-account-header>
  </div>

  <div class="form">
    <h3 class="text-center">Recover Account</h3>
    <p class="text-center">To restore your account enter you e-mail address and we will send to you recovery instructions.</p>
    <form *ngIf="!loading else spinner" (ngSubmit)="onSubmit()">
      <mat-form-field class="input">
        <input matInput placeholder="E-mail"
               [formControl]="emailFormControl"
               >
        <mat-error *ngIf="emailFormControl.errors?.backend">{{error}}</mat-error>  
      </mat-form-field>

      <button style="color: #548EFF;" class="my-1" mat-raised-button>RECOVER</button>

    </form>
  </div>

  <ng-template #spinner><mat-spinner class="mx-auto"></mat-spinner></ng-template>

  <div class="footer">
    <div class="text-center">
      <a [routerLink]="['/account/log-in']">Go back to log-in page</a>
    </div>
  </div>

</div>
