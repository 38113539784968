import { environment } from '../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { I7Event } from '../shared/models/i7event';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../shared/models/user';
import { I7eventImage } from '../shared/models/i7event-image';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var API_URL = environment.apiUrl;
var I7EventService = /** @class */ (function () {
    function I7EventService(http) {
        this.http = http;
    }
    I7EventService.prototype.getLikedEvents = function (page, pageSize) {
        var params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('page_size', pageSize.toString());
        params = params.append('liked-only', '1');
        return this.http.get(API_URL + '/events/', { params: params }).pipe(map(function (data) { return data.map(function (event) { return new I7Event(event); }); }));
    };
    I7EventService.prototype.get = function (id) {
        return this.http.get(API_URL + "/events/" + id).pipe(map(function (data) { return new I7Event(data); }));
    };
    I7EventService.prototype.updatePartial = function (id, body) {
        return this.http.patch(API_URL + "/events/" + id, body).pipe(tap(function () { return console.log(body); }));
    };
    I7EventService.prototype.update = function (i7event) {
        return this.http.put(API_URL + "/events/" + i7event.id, i7event);
    };
    I7EventService.prototype.delete = function (i7eventId) {
        return this.http.delete(API_URL + "/events/" + i7eventId);
    };
    I7EventService.prototype.getParticipants = function (id) {
        return this.http.get(API_URL + "/events/" + id + "/users/").pipe(map(function (data) { return data.map(function (user) { return new User(user.user); }); }));
    };
    I7EventService.prototype.list = function (params) {
        if (params === void 0) { params = {}; }
        var p = new HttpParams();
        for (var _i = 0, _a = Object.keys(params); _i < _a.length; _i++) {
            var k = _a[_i];
            p = p.append(k, params[k]);
        }
        return this.http
            .get(API_URL + "/events/", { params: p })
            .pipe(map(function (data) { return data.map(function (event) { return new I7Event(event); }); }));
    };
    I7EventService.prototype.getCommonEvents = function (userId) {
        return this.list();
    };
    I7EventService.prototype.getEventsOrganizedByUser = function (userId) {
        return this.list().pipe(map(function (data) { return data.filter(function (ev) { return ev.author.id === userId; }).map(function (ev) { return new I7Event(ev); }); }));
    };
    I7EventService.prototype.create = function (event) {
        event.geo_coordinates = "POINT(" + event.coordinates.latitude + " " + event.coordinates.longtitude + ")";
        return this.http.post(API_URL + '/events/create', event).pipe(catchError(this.handleError('create Event')));
    };
    I7EventService.prototype.publish = function (id, image) {
        var data = new FormData();
        data.append('image_normal', image);
        return this.http.post(API_URL + "/events/" + id + "/publish", data).pipe(catchError(this.handleError('publish Event')));
    };
    I7EventService.prototype.like = function (id) {
        return this.http.post(API_URL + "/events/" + id + "/like", { id: id });
    };
    I7EventService.prototype.unlike = function (id) {
        return this.http.post(API_URL + "/events/" + id + "/unlike", { id: id });
    };
    I7EventService.prototype.addUser = function (i7EventId, username) {
        return this.http.post(API_URL + "/events/" + i7EventId + "/users/", { username: username });
    };
    I7EventService.prototype.removeUser = function (i7EventId, username) {
        return this.http.delete(API_URL + "/events/" + i7EventId + "/users/" + username);
    };
    I7EventService.prototype.handleError = function (operation) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            console.log(error);
            console.log(operation + " failed: " + error.message);
            return throwError(error);
        };
    };
    I7EventService.prototype.uploadImage = function (i7EventId, file) {
        var data = new FormData();
        data.append('image_raw', file);
        return this.http.post(API_URL + "/events/" + i7EventId + "/images/", data);
    };
    I7EventService.prototype.listEventImages = function (i7eventId) {
        return this.http.get(API_URL + "/events/" + i7eventId + "/images/").pipe(map(function (data) { return data.map(function (img) { return new I7eventImage(img); }); }));
    };
    I7EventService.prototype.getI7EventImage = function (eventId, photoId) {
        return this.http.get(API_URL + "/events/" + eventId + "/images/" + photoId);
    };
    I7EventService.prototype.unlikeEventImage = function (i7eventId, id) {
        return this.http.post(API_URL + "/events/" + i7eventId + "/images/" + id + "/unlike", {}).toPromise();
    };
    I7EventService.prototype.likeEventImage = function (i7EventId, id) {
        return this.http.post(API_URL + "/events/" + i7EventId + "/images/" + id + "/like", {}).toPromise();
    };
    I7EventService.prototype.deleteImage = function (i7event, id) {
        return this.http.delete(API_URL + "/events/" + i7event + "/images/" + id);
    };
    I7EventService.ngInjectableDef = i0.defineInjectable({ factory: function I7EventService_Factory() { return new I7EventService(i0.inject(i1.HttpClient)); }, token: I7EventService, providedIn: "root" });
    return I7EventService;
}());
export { I7EventService };
