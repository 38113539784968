import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var API_URL = environment.apiUrl;
var WebsocketTokenService = /** @class */ (function () {
    function WebsocketTokenService(http) {
        var _this = this;
        this.http = http;
        this.issue = function () {
            return _this.getToken();
        };
    }
    WebsocketTokenService.prototype.getToken = function () {
        return this.http.post(API_URL + "/ws-tokens/", {}).pipe(map(function (data) { return data.token; })).toPromise();
    };
    WebsocketTokenService.ngInjectableDef = i0.defineInjectable({ factory: function WebsocketTokenService_Factory() { return new WebsocketTokenService(i0.inject(i1.HttpClient)); }, token: WebsocketTokenService, providedIn: "root" });
    return WebsocketTokenService;
}());
export { WebsocketTokenService };
