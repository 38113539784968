import { of } from 'rxjs';
import * as i0 from "@angular/core";
var NotificationService = /** @class */ (function () {
    function NotificationService() {
        this.notifications = [];
    }
    NotificationService.prototype.getNotifications = function () {
        return of(this.notifications);
    };
    NotificationService.prototype.push = function (notification) {
        var messages = this.notifications.map(function (el) { return el.text; });
        if (messages.indexOf(notification.text) === -1) {
            this.notifications.push(notification);
        }
    };
    NotificationService.prototype.pop = function () {
        this.notifications.pop();
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
