<div class="header">
  <app-account-header></app-account-header>
</div>

<app-avatar class="mx-auto" [imgSrc]="user.profile_picture_small"></app-avatar>

<span class="text-center mx-auto my-2">{{user.username}}</span>

<span class="text-center my-3">
  Your password has been successfully set.
</span>

<div class="footer">
  <div class="text-center">
    <a [routerLink]="['/account/log-in']">Go back to log-in page</a>
  </div>
</div>