<div class="friends-preview-wrapper">
  <div class="friends-preview">
    <div class="friend-tile" *ngFor="let friend of friends">
      <div
        class="friend-avatar shadow-sm pointer"
        [routerLink]="['/users/', friend.id]"
        [ngStyle]="{'background-image': 'url(' + friend.profile_picture_small + ')'}"></div>
      <a [routerLink]="['/users/', friend.id]" class="text-center username pt-1">{{friend.username}}</a>
    </div>
  </div>
  <div class="opacity-cover"></div>
</div>
