import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var API_URL = environment.apiUrl;
var PasswordRestorationService = /** @class */ (function () {
    function PasswordRestorationService(http) {
        this.http = http;
    }
    PasswordRestorationService.prototype.getUser = function () {
        return of(this.user);
    };
    PasswordRestorationService.prototype.restoreCreate = function (email) {
        return this.http.post(API_URL + "/account/password-restorations/", { email: email });
    };
    PasswordRestorationService.prototype.restoreRead = function (key) {
        var _this = this;
        return this.http.get(API_URL + "/account/password-restorations/" + key).pipe(map(function (user) { return _this.user = user; }));
    };
    PasswordRestorationService.prototype.restore = function (key, restorePassword) {
        return this.http.post(API_URL + "/account/password-restorations/" + key + "/restore", restorePassword);
    };
    PasswordRestorationService.ngInjectableDef = i0.defineInjectable({ factory: function PasswordRestorationService_Factory() { return new PasswordRestorationService(i0.inject(i1.HttpClient)); }, token: PasswordRestorationService, providedIn: "root" });
    return PasswordRestorationService;
}());
export { PasswordRestorationService };
