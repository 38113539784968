<div class="header">
  <app-account-header></app-account-header>
</div>

<h3 class="text-center">Recover Account</h3>
<p class="text-center">
  An email has been sent to your email address. 
  Follow the directions in the email to recover your password and then sign in again.
</p>

<div class="footer">
    <div class="text-center">
      <a [routerLink]="['/account/log-in']">Go back to log-in page</a>
    </div>
</div>