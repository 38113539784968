/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./o-auth2-log-in.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./o-auth2-log-in.component";
import * as i3 from "../../../../services/authorization.service";
var styles_OAuth2LogInComponent = [i0.styles];
var RenderType_OAuth2LogInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OAuth2LogInComponent, data: {} });
export { RenderType_OAuth2LogInComponent as RenderType_OAuth2LogInComponent };
export function View_OAuth2LogInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Redirecting to authorization service..."]))], null, null); }
export function View_OAuth2LogInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-oauth2-log-in", [], null, null, null, View_OAuth2LogInComponent_0, RenderType_OAuth2LogInComponent)), i1.ɵdid(1, 114688, null, 0, i2.OAuth2LogInComponent, [i3.AuthorizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OAuth2LogInComponentNgFactory = i1.ɵccf("app-oauth2-log-in", i2.OAuth2LogInComponent, View_OAuth2LogInComponent_Host_0, {}, {}, []);
export { OAuth2LogInComponentNgFactory as OAuth2LogInComponentNgFactory };
