var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { objectToQuery } from '../../constrib/url_utils';
var AuthenticatedWebsocket = /** @class */ (function () {
    function AuthenticatedWebsocket(url, queryParams, onMessage, issuer) {
        var _this = this;
        this.permClosed = false;
        this.isConnected = false;
        this.socket = null;
        this.partial = '';
        this.reconnectionTries = 0;
        this.send = function (data) {
            var msg = JSON.stringify(data);
            _this.socket.send(msg);
        };
        this.try_send = function (data) {
            if (_this.isConnected) {
                _this.send(data);
            }
            else {
                setTimeout(function () { _this.try_send(data); }, 300);
            }
        };
        this.close = function () {
            if (!_this.socket) {
                return;
            }
            _this.socket.close();
            _this.isConnected = false;
            _this.permClosed = true;
            _this.partial = '';
            _this.reconnectionTries = 0;
        };
        this.connect = function () { return __awaiter(_this, void 0, void 0, function () {
            var token, params, queryPart, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.reconnectionTries += 1;
                        if (this.socket !== null) {
                            this.socket.close();
                        }
                        return [4 /*yield*/, this.tokenIssuer.issue()];
                    case 1:
                        token = _a.sent();
                        params = __assign({}, this.queryParams, { token: token });
                        queryPart = objectToQuery(params);
                        url = "" + this.url + queryPart;
                        this.socket = new WebSocket(url);
                        this.socket.onopen = this.onopen;
                        this.socket.onmessage = this.onmessage;
                        this.socket.onclose = this.onclose;
                        return [2 /*return*/];
                }
            });
        }); };
        this.onopen = function (event) {
            _this.isConnected = true;
            _this.reconnectionTries = 0;
        };
        this.onmessage = function (event) {
            try {
                var data = JSON.parse(_this.partial + event.data);
                _this.partial = '';
                _this.onMessage(data);
            }
            catch (err) {
                _this.partial += event.data;
            }
        };
        this.onclose = function () {
            _this.socket = null;
            _this.isConnected = false;
            if (!_this.permClosed) {
                _this.reconnect();
            }
        };
        this.reconnect = function () {
            var timeout = Math.pow(2, _this.reconnectionTries) / 2;
            setTimeout(_this.connect, timeout);
        };
        this.url = url;
        this.onMessage = onMessage;
        this.tokenIssuer = issuer;
        this.queryParams = queryParams;
        this.connect();
    }
    return AuthenticatedWebsocket;
}());
export { AuthenticatedWebsocket };
