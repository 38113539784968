/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./restore-password-accepted.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../helpers/header/header.component.ngfactory";
import * as i3 from "../helpers/header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./restore-password-accepted.component";
var styles_RestorePasswordAcceptedComponent = [i0.styles];
var RenderType_RestorePasswordAcceptedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RestorePasswordAcceptedComponent, data: {} });
export { RenderType_RestorePasswordAcceptedComponent as RenderType_RestorePasswordAcceptedComponent };
export function View_RestorePasswordAcceptedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-account-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recover Account"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" An email has been sent to your email address. Follow the directions in the email to recover your password and then sign in again.\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 1), (_l()(), i1.ɵted(-1, null, ["Go back to log-in page"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 11, 0, "/account/log-in"); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 10).target; var currVal_1 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_0, currVal_1); }); }
export function View_RestorePasswordAcceptedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-restore-password-accepted", [], null, null, null, View_RestorePasswordAcceptedComponent_0, RenderType_RestorePasswordAcceptedComponent)), i1.ɵdid(1, 114688, null, 0, i6.RestorePasswordAcceptedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RestorePasswordAcceptedComponentNgFactory = i1.ɵccf("app-restore-password-accepted", i6.RestorePasswordAcceptedComponent, View_RestorePasswordAcceptedComponent_Host_0, {}, {}, []);
export { RestorePasswordAcceptedComponentNgFactory as RestorePasswordAcceptedComponentNgFactory };
