<div class="form">
  <h4 class="text-center">Set a new password</h4>

  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <mat-form-field class="input w-100" [ngClass]="error?.old_password?.length > 50 ? 'my-3' : 'my-1'">

      <input type="password" matInput placeholder="Enter old password" formControlName="old_password">

      <mat-error *ngIf="form.controls.old_password.errors?.required">This field is required</mat-error>
      <mat-error *ngIf="form.controls.old_password.errors?.minlength">Minimum length is 8</mat-error>
      <mat-error *ngIf="form.controls.old_password.errors?.backend">{{error?.old_password}}</mat-error>
    </mat-form-field>

    <mat-form-field class="input w-100" [ngClass]="error?.new_password?.length > 50 ? 'my-3' : 'my-1'">

      <input type="password" matInput placeholder="Enter a new password" formControlName="new_password">

      <mat-error *ngIf="form.controls.new_password.errors?.required">This field is required</mat-error>
      <mat-error *ngIf="form.controls.new_password.errors?.minlength">Minimum length is 8</mat-error>
      <mat-error *ngIf="form.controls.new_password.errors?.backend">{{error?.new_password}}</mat-error>
    </mat-form-field>

    <mat-form-field class="input w-100">
      <input type="password" matInput placeholder="Confirm new password" formControlName="confirmPassword">
      <mat-error *ngIf="form.controls.confirmPassword.errors?.required">This field is required</mat-error>
      <mat-error *ngIf="form.controls.confirmPassword.errors?.minlength">Minimum length is 8</mat-error>
      <mat-error *ngIf="form.controls.confirmPassword.errors?.MatchPassword">Passwords are not the same</mat-error>
    </mat-form-field>

    <mat-dialog-actions align="end">
      <button mat-dialog-close class="my-2" mat-raised-button>Back</button>
      <div class="mr-2">
          <app-spinner [diameter]="30" [strokeWidth]="2" *ngIf="loading"></app-spinner>
      </div>

      <button class="my-2" style="color: #548EFF;" mat-raised-button>Submit</button>
    </mat-dialog-actions>

  </form>
</div>