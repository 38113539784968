<div class="header">
    <app-account-header></app-account-header>
  </div>
  
  <h3 class="text-center">Sign up</h3>
  <p class="text-center">
   Your account has been successfully created. Activation link has been sent to your email address. 
  </p>
  
  <div class="footer">
      <div class="text-center">
        <a [routerLink]="['/account/log-in']">Go back to log-in page</a>
      </div>
  </div>