import { environment } from '../environments/environment';
import { Subject, forkJoin } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Message } from '../shared/models/message';
import { map, tap } from 'rxjs/operators';
import { I7EventMessageType, I7EventMessageWebsocket } from '../modules/utils/websockets/event-message';
import { WebsocketTokenService } from './websocket-token.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./websocket-token.service";
var API_URL = environment.apiUrl;
var MessageService = /** @class */ (function () {
    function MessageService(http, tokenIssuer) {
        var _this = this;
        this.http = http;
        this.tokenIssuer = tokenIssuer;
        this.eventMessages = [];
        this.onWebsocketMessage = function (result) {
            switch (result.type) {
                case I7EventMessageType.newMessage:
                    _this.handleNewMessage(new Message(result.data));
                    break;
                case I7EventMessageType.updatedMessage:
                    _this.eventMessages.map(function (x) { if (x.id === result.data.id) {
                        return result;
                    } });
                    break;
                case I7EventMessageType.deletedMessage:
                    _this.eventMessages.find(function (x) { return x.id === result.data.id; }).removed = true;
                    break;
                case I7EventMessageType.messageLikeUpdated:
                    _this.eventMessages.find(function (x) { return x.id === result.data.i7event_message_id; }).likes = result.data.likes_count;
                    break;
                default:
                    console.warn("Received data with type " + result.type + " on I7EventWebsocket. This type have no handler.");
            }
            return _this.messageSource.next(_this.eventMessages);
        };
        this.handleNewMessage = function (message) {
            if (_this.messageDetails && message.in_response_to === _this.messageDetails.id ||
                (!_this.messageDetails && message.in_response_to === null)) {
                return _this.eventMessages.push(message);
            }
            _this.eventMessages.find(function (x) { return x.id === message.in_response_to; }).replies_count++;
        };
        this.startListening = function (i7EventId) {
            _this.messageDetails = null;
            _this.eventMessages = [];
            if (_this.ws) {
                _this.ws.close();
            }
            _this.ws = new I7EventMessageWebsocket(_this.onWebsocketMessage, _this.tokenIssuer, i7EventId);
        };
        this.stopListening = function () {
            _this.ws.close();
            _this.ws = null;
        };
        this.getMessageWithResponses = function (i7EventId, messageId) {
            forkJoin(_this.getMessage(i7EventId, messageId), _this.getResponses(i7EventId, messageId))
                .subscribe(function (result) {
                _this.messageDetails = new Message(result[0]);
                _this.eventMessages = [result[0]].concat(result[1]);
                _this.messageSource.next(_this.eventMessages);
            });
            return _this.messageSource.asObservable();
        };
        this.handleMessageLike = function (message) {
            if (message.removed) {
                return;
            }
            message.likes++;
            message.my_like = true;
            _this.like(message.i7event, message.id).subscribe(function () { return _this.messageSource.next(_this.eventMessages); }, function () { message.likes--; message.my_like = false; });
        };
        this.handleMessageUnlike = function (message) {
            message.likes--;
            message.my_like = false;
            _this.unlike(message.i7event, message.id).subscribe(function () { return _this.messageSource.next(_this.eventMessages); }, function () { message.likes++; message.my_like = true; });
        };
        this.like = function (i7eventId, messageId) {
            return _this.http.post(API_URL + "/events/" + i7eventId + "/messages/" + messageId + "/like", { i7event_pk: i7eventId, id: messageId });
        };
        this.unlike = function (i7eventId, messageId) {
            return _this.http.post(API_URL + "/events/" + i7eventId + "/messages/" + messageId + "/unlike", { i7event_pk: i7eventId, id: messageId });
        };
        this.messageSource = new Subject();
    }
    MessageService.prototype.getMessage = function (i7EventId, messageId) {
        var _this = this;
        return this.http.get(API_URL + "/events/" + i7EventId + "/messages/" + messageId).pipe(map(function (msg) { return new Message(msg); }), tap(function (msg) { return _this.messageDetails = msg; }));
    };
    MessageService.prototype.getResponses = function (i7EventId, messageId) {
        var params = new HttpParams();
        params = params.append('in_response_to', messageId);
        return this.http.get(API_URL + "/events/" + i7EventId + "/messages/", { params: params })
            .pipe(map(function (data) { return data.map(function (msg) { return new Message(msg); }); }));
    };
    MessageService.prototype.sendMessage = function (message) {
        var _this = this;
        var body = {
            in_response_to: message.in_response_to,
            body: message.body
        };
        this.http.post(API_URL + "/events/" + message.i7event + "/messages/", body)
            .subscribe(function () { return _this.messageSource.next(_this.eventMessages); });
        return this.messageSource.asObservable();
    };
    MessageService.prototype.updateMessage = function (message) {
        var _this = this;
        var body = {
            in_response_to: message.in_response_to,
            body: message.body
        };
        this.http.patch(API_URL + "/events/" + message.i7event + "/messages/" + message.id, body)
            .subscribe(function () { return _this.messageSource.next(_this.eventMessages); });
        return this.messageSource.asObservable();
    };
    MessageService.prototype.deleteMessage = function (message) {
        var _this = this;
        this.http.delete(API_URL + "/events/" + message.i7event + "/messages/" + message.id).subscribe(function () { return _this.messageSource.next(_this.eventMessages); });
        return this.messageSource.asObservable();
    };
    MessageService.prototype.getEventMessages = function (id) {
        var _this = this;
        this.http.get(API_URL + "/events/" + id + "/messages/")
            .subscribe(function (data) {
            _this.eventMessages = data;
            _this.messageSource.next(_this.eventMessages);
        });
        return this.messageSource.asObservable();
    };
    MessageService.ngInjectableDef = i0.defineInjectable({ factory: function MessageService_Factory() { return new MessageService(i0.inject(i1.HttpClient), i0.inject(i2.WebsocketTokenService)); }, token: MessageService, providedIn: "root" });
    return MessageService;
}());
export { MessageService };
