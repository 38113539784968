/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../utils/components/notification/notification.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../../../utils/components/notification/notification.component";
import * as i5 from "../../../../services/notification.service";
import * as i6 from "@angular/router";
import * as i7 from "./account.component";
var styles_AccountComponent = [i0.styles];
var RenderType_AccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountComponent, data: {} });
export { RenderType_AccountComponent as RenderType_AccountComponent };
export function View_AccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-notification", [], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "width": 0, "position": 1 }), i1.ɵdid(3, 114688, null, 0, i4.NotificationComponent, [i5.NotificationService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "account-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "content container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "image"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "100%", "absolute"); _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); _ck(_v, 7, 0); }, null); }
export function View_AccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-acount", [], null, null, null, View_AccountComponent_0, RenderType_AccountComponent)), i1.ɵdid(1, 49152, null, 0, i7.AccountComponent, [], null, null)], null, null); }
var AccountComponentNgFactory = i1.ɵccf("app-acount", i7.AccountComponent, View_AccountComponent_Host_0, {}, {}, []);
export { AccountComponentNgFactory as AccountComponentNgFactory };
