/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i7 from "@angular/material/progress-spinner";
import * as i8 from "@angular/common";
import * as i9 from "../helpers/header/header.component.ngfactory";
import * as i10 from "../helpers/header/header.component";
import * as i11 from "./registration-complete.component";
import * as i12 from "../../../../services/registration.service";
import * as i13 from "@angular/router";
var styles_RegistrationCompleteComponent = [];
var RenderType_RegistrationCompleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegistrationCompleteComponent, data: {} });
export { RenderType_RegistrationCompleteComponent as RenderType_RegistrationCompleteComponent };
function View_RegistrationCompleteComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Thanks for signing up!"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Click this button to activate your account"])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "button", [["mat-raised-button", ""], ["style", "color: #548EFF;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(7, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.Platform, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["ACTIVATE"]))], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 7).disabled || null); var currVal_1 = (i0.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_0, currVal_1); }); }
function View_RegistrationCompleteComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mx-auto mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i6.View_MatSpinner_0, i6.RenderType_MatSpinner)), i0.ɵdid(1, 49152, null, 0, i7.MatSpinner, [i0.ElementRef, i3.Platform, [2, i8.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i7.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 1).diameter; var currVal_2 = i0.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_RegistrationCompleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "registration-complete-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-account-header", [], null, null, null, i9.View_HeaderComponent_0, i9.RenderType_HeaderComponent)), i0.ɵdid(3, 49152, null, 0, i10.HeaderComponent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RegistrationCompleteComponent_1)), i0.ɵdid(5, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["spinner", 2]], null, 0, null, View_RegistrationCompleteComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; var currVal_1 = i0.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_RegistrationCompleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-registration-complete", [], null, null, null, View_RegistrationCompleteComponent_0, RenderType_RegistrationCompleteComponent)), i0.ɵdid(1, 114688, null, 0, i11.RegistrationCompleteComponent, [i12.RegistrationService, i13.ActivatedRoute, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistrationCompleteComponentNgFactory = i0.ɵccf("app-registration-complete", i11.RegistrationCompleteComponent, View_RegistrationCompleteComponent_Host_0, {}, {}, []);
export { RegistrationCompleteComponentNgFactory as RegistrationCompleteComponentNgFactory };
