import {Component} from '@angular/core'

@Component({
  selector: 'app-dialog',
  template: `
    <div>
      <p>Your account has been activated</p>
      <p>Now you can log-in</p>
    </div>
  `
})
export class ActivationDialogComponent {
}
