<div class="container pt-3">

  <div *ngIf="user else spinner">
    <div (click)="fileInput.click()" class="avatar d-flex justify-content-around"
         [ngStyle]="{'background-image': 'url(' + user.profile_picture_normal + ')'}">
      <div class="choose-photo text-center">
        <span>Change picture</span>
      </div>
      <input type="file" style="display:none;" (change)="onFileChanged($event)" #fileInput>
    </div>
  </div>
  <br>

  <!--Account Settings Button -->
  <div class="d-flex flex-column">
    <p class="text-center" *ngIf="user">{{user.username}}</p>
    <div class="d-flex">
      <button [routerLink]="['/settings/account']" class="btn btn-primary btn-sm mx-auto account-settings-btn shadow-sm">Account Settings</button>
    </div>
  </div>

</div>
<ng-template #spinner>
  <mat-spinner class="mx-auto my-2"></mat-spinner>
</ng-template>

<!--Friends section-->
<div class="container mt-3">
  <app-friends-bar></app-friends-bar>
</div>
<div class="d-flex mt-2">
  <button
    [routerLink]="['/friends']"
    class="btn btn-primary btn-sm ml-auto mr-2 account-settings-btn shadow-sm"
  >
    Manage Friends
  </button>
  <button
    [routerLink]="['events']"
    class="btn btn-primary btn-sm mr-auto ml-2 account-settings-btn shadow-sm"
  >
    Manage Events
  </button>
</div>

<!--Album-->

<app-profile-album></app-profile-album>
