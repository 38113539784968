<div class="wrapper" *ngIf="message && !message.removed else removed">
  <div class="header my-2 px-2">
    <div class="p-2" *ngIf="message.author">
      <app-avatar [imgSrc]="message.author.profile_picture_small" (click)="gotoUserProfile(message.author.id)"></app-avatar>
    </div>
    <div class="name-wrapper">
      <div class="name">
        <div class="pointer" (click)="gotoUserProfile(message.author.id)" *ngIf="(message.author && !message.author.is_deleted) else deleted">
            {{message.author?.username}}
        </div>
        <ng-template #deleted>
          <span class="text-muted font-italic">{{message.author?.username}}</span>
        </ng-template>
      </div>
      <div class="date text-muted">
        {{message.created | date}}
      </div>
    </div>
    <button *ngIf="removable || editable" class="ribbon" mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu">
      <button *ngIf="editable" mat-menu-item (click)="editMessage()">
        <mat-icon>edit</mat-icon>
        Edit
      </button>
      <button *ngIf="removable" mat-menu-item (click)="deleteMessage()">
        <mat-icon>delete</mat-icon>
        delete
      </button>
    </mat-menu>
  </div>
<div>
  <div class="content px-3">
   <span *ngIf="!editMode">{{message.body}}</span> 

   <span class="w-100" *ngIf="editMode">
      <mat-form-field class="w-100">
          <textarea matInput [(ngModel)]="editedMessage.body" type="text"></textarea>
      </mat-form-field>
   </span>
  </div>
  <div *ngIf="!editMode else edit" class="footer mx-2 d-flex justify-content-between">
    <div class="button-wrap d-flex">
      <button class="footer-button p-0 d-flex" mat-button (click)="handleReplyClick()">
        <mat-icon class="align-self-center mb-1">reply</mat-icon><b>Reply</b>
      </button>
      <button (click)="handleVoteClick()" class="footer-button p-1 d-flex mx-1" mat-button>
        <b>{{message.likes}} Upvote</b>
        <mat-icon class="align-self-center mb-1">star</mat-icon>
      </button>
    </div>
    <button *ngIf="!hideSeeAllButton" (click)="goToMessageDetails()" class="footer-button d-flex" mat-button>
      <mat-icon class="align-self-center mb-1 mr-1">reply_all</mat-icon><b>See ({{message.replies_count}})</b>
    </button>
  </div>
</div>
<ng-template #edit>
    <div class="footer mx-2 d-flex justify-content-between">
        <button class="footer-button" mat-button (click)="cancelEdit()">
            <mat-icon>cancel</mat-icon> Cancel 
          </button>
          
        <button class="footer-button" mat-button (click)="saveMessage()">
          <mat-icon>send</mat-icon> Save 
        </button>
      </div>
  </ng-template>
</div>

<ng-template #removed>
  <div class="deleted">
    <div *ngIf="message" class="header p-2">
      <div class="date mt-2 text-muted">
        {{message.created | date}}
      </div>
    </div>
    <div class="content text-muted my-3 p-2">
      <i>Comment deleted</i>
    </div>
    <div *ngIf="message" class="footer mx-2 d-flex justify-content-between">
        <div class="button-wrap d-flex">
          <button class="footer-button p-0 d-flex" mat-button (click)="handleReplyClick()">
            <mat-icon class="align-self-center mb-1">reply</mat-icon><b>Reply</b>
          </button>
          <button (click)="handleVoteClick()" class="footer-button p-1 d-flex mx-1" mat-button>
          <b>{{message.likes}} Upvote</b> <mat-icon class="align-self-center mb-1">star</mat-icon>
          </button>
        </div>
        <button *ngIf="!hideSeeAllButton" (click)="goToMessageDetails()" class="footer-button d-flex" mat-button>
          <mat-icon class="align-self-center mb-1 mr-1">reply_all</mat-icon><b>See ({{message.replies_count}})</b>
        </button>
      </div>
  </div>
</ng-template>

<app-message-create *ngIf="message.replyClicked && !messageDetails" [inResponseTo]="message.id" [eventId]="message.i7event"></app-message-create>  