import {Component} from '@angular/core'

@Component({
  selector: 'app-acount',
  templateUrl: './account.component.html',
  styleUrls: ['account.component.css'],
})
export class AccountComponent {

}
