<div class="registration-complete-container">

  <div class="header">
    <app-account-header></app-account-header>
  </div>

  <div *ngIf="!loading else spinner" class="body">
    <h3 class="text-center">Thanks for signing up!</h3>
    <p class="text-center">Click this button to activate your account</p>

    <div class="text-center">
      <button style="color: #548EFF;" mat-raised-button (click)="activate()">ACTIVATE</button>
    </div>

  </div>

  <ng-template #spinner><mat-spinner class="mx-auto"></mat-spinner></ng-template>

</div>
