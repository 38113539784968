/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./avatar.component";
var styles_AvatarComponent = [i0.styles];
var RenderType_AvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvatarComponent, data: {} });
export { RenderType_AvatarComponent as RenderType_AvatarComponent };
export function View_AvatarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "align-self-center shadow-sm mat-card-avatar"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (("url(" + _co.imgSrc) + ")")); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-avatar", [], null, null, null, View_AvatarComponent_0, RenderType_AvatarComponent)), i1.ɵdid(1, 114688, null, 0, i3.AvatarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvatarComponentNgFactory = i1.ɵccf("app-avatar", i3.AvatarComponent, View_AvatarComponent_Host_0, { imgSrc: "imgSrc" }, {}, []);
export { AvatarComponentNgFactory as AvatarComponentNgFactory };
