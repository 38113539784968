<div class="mt-4">
  <app-spinner *ngIf="!loaded"></app-spinner>
  <div *ngIf="loaded" class="photo-library">

    <div
      *ngFor="let event of events"
      class="photo-wrap pointer"
      [routerLink]="['/events', event.id, 'photos']"
    >
      <div class="ext-1">
        <div class="ext-2">
          <img [src]="event.image_normal">
        </div>
      </div>

    </div>

  </div>
  <div *ngIf="loaded && !events.length">
    <p class="text-muted text-center">Events that you took part in will be displayed here.</p>
  </div>
</div>
