/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/router";
import * as i8 from "../../../utils/components/friends-bar/friends-bar.component.ngfactory";
import * as i9 from "../../../utils/components/friends-bar/friends-bar.component";
import * as i10 from "../../../../services/user.service";
import * as i11 from "../profile-album/profile-album.component.ngfactory";
import * as i12 from "../profile-album/profile-album.component";
import * as i13 from "../../../../services/i7event.service";
import * as i14 from "./settings.component";
var styles_SettingsComponent = [i0.styles];
var RenderType_SettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsComponent, data: {} });
export { RenderType_SettingsComponent as RenderType_SettingsComponent };
function View_SettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "avatar d-flex justify-content-around"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "choose-photo text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Change picture"])), (_l()(), i1.ɵeld(7, 0, [["fileInput", 1]], null, 0, "input", [["style", "display:none;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (("url(" + _co.user.profile_picture_normal) + ")")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.username; _ck(_v, 1, 0, currVal_0); }); }
function View_SettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mx-auto my-2 mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i3.View_MatSpinner_0, i3.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i4.MatSpinner, [i1.ElementRef, i5.Platform, [2, i2.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn-primary btn-sm mx-auto account-settings-btn shadow-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["Account Settings"])), (_l()(), i1.ɵand(0, [["spinner", 2]], null, 0, null, View_SettingsComponent_3)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "container mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-friends-bar", [], null, null, null, i8.View_FriendsBarComponent_0, i8.RenderType_FriendsBarComponent)), i1.ɵdid(15, 114688, null, 0, i9.FriendsBarComponent, [i10.UserService], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 8, "div", [["class", "d-flex mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["class", "btn btn-primary btn-sm ml-auto mr-2 account-settings-btn shadow-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(19, 1), (_l()(), i1.ɵted(-1, null, [" Manage Friends "])), (_l()(), i1.ɵeld(21, 0, null, null, 3, "button", [["class", "btn btn-primary btn-sm mr-auto ml-2 account-settings-btn shadow-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(23, 1), (_l()(), i1.ɵted(-1, null, [" Manage Events "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "app-profile-album", [], null, null, null, i11.View_ProfileAlbumComponent_0, i11.RenderType_ProfileAlbumComponent)), i1.ɵdid(26, 114688, null, 0, i12.ProfileAlbumComponent, [i13.I7EventService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = i1.ɵnov(_v, 12); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.user; _ck(_v, 6, 0, currVal_2); var currVal_3 = _ck(_v, 10, 0, "/settings/account"); _ck(_v, 9, 0, currVal_3); _ck(_v, 15, 0); var currVal_4 = _ck(_v, 19, 0, "/friends"); _ck(_v, 18, 0, currVal_4); var currVal_5 = _ck(_v, 23, 0, "events"); _ck(_v, 22, 0, currVal_5); _ck(_v, 26, 0); }, null); }
export function View_SettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings", [], null, null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i1.ɵdid(1, 114688, null, 0, i14.SettingsComponent, [i10.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsComponentNgFactory = i1.ɵccf("app-settings", i14.SettingsComponent, View_SettingsComponent_Host_0, {}, {}, []);
export { SettingsComponentNgFactory as SettingsComponentNgFactory };
