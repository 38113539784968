import * as i0 from "@angular/core";
var UtilsService = /** @class */ (function () {
    function UtilsService() {
    }
    UtilsService.prototype.setErrors = function (error, formGroup) {
        debugger;
        var err = this.getError(error);
        Object.keys(formGroup.controls).forEach(function (key) {
            if (err[key]) {
                formGroup.controls[key].setErrors({ backend: true });
                formGroup.controls[key].markAsTouched();
            }
        });
        return err;
    };
    UtilsService.prototype.getError = function (error) {
        var properties = Object.keys(error.error);
        var t = {};
        properties.forEach(function (x) {
            t[x] = error.error[x].join(' ');
        });
        return t;
    };
    UtilsService.prototype.getTimeDiffFormatted = function (event) {
        var startDate = new Date(event.start).getTime();
        var now = new Date().getTime();
        var delta = Math.abs(startDate - now) / 1000;
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;
        if (startDate < now) {
            return days + " days ago";
        }
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        return "in " + days + " days and " + hours + " hours";
    };
    UtilsService.ngInjectableDef = i0.defineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
